import * as React from "react"
import GradientJumbo from "../../components/gradient-jumbo"
import Layout from "../../components/layout"
import Contact from "../../components/sections/contact"
import Seo from "../../components/seo"

const ContatoPage = props => (
  <Layout language="en" pathname={props.location.pathname}>
    <Seo
      title="Contact"
      lang="en"
      description="Do you need help? We can assist you!"
    />
    <GradientJumbo subtitle="Contact" title="Need any help?" />
    <Contact language="en" />
  </Layout>
)

export default ContatoPage
